// theme.js
module.exports = {
  base: "16px",
  spacing: "1rem",
  breakpoints: {
    xs: "576" /* 0px */ ,
    sm: "700" /* 480px */ ,
    md: "992" /* 1024px */ ,
    lg: "1200" /* 1200px */
  },
  typography: {
    font: "'Open Sans', sans-serif",
    text: "1rem",
    title: "2rem"
  },
  colors: {
    /* Color for background*/
    colorA: "rgba(248,247,242,1)",
    /* Color for Text*/
    colorB: "#2c362d",
    /* Color for styled text like heading or Menues*/
    colorC: "rgba(182,200,194,1)",
    colorD: "#1fce6d",
    colorE: "#e94b35",
    colorF: "#e94b35"
  },
};
